import React from "react"
// import { Link } from "gatsby" // we use wrapper one from plugin = gatsby-plugin-intl
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { graphql } from "gatsby"

// s4n custom code
import FragmentPageDetails from './../model/fragments/nodePage/page-details'
import { useIntl } from "gatsby-plugin-intl"
import { useParseInlineImages } from '../hooks/drupal/parseInlineImages'
// import DrupalModuleParagraphsContainer from './../components/drupal/module/paragraphs/container'
// import Widgets from "./../components/widgets"
// import Team from './../components/s4n/Team/index-static';
// import loadable from "@loadable/component"
// import ImagesIllustrationsLeafBg from './../assets/images/illustrations/leaf-bg.png';
// import Portfolio from './../components/s4n/Portfolio'
// import Partners from './../components/s4n/Partners'
// import Testimonials from './../components/s4n/Testimonials'

// Subpages
// import kebabCase from "lodash.kebabcase"
// import Link from "./../components/s4n/Link"
// import Page from "./../model/node/page"
// import Hero from './../components/s4n/ProjectSpecific/Hero'
import useReplacementNode from './../hooks/drupal/parseContentProcessor/useReplacement/Node'

// import ImageUtils from './../utils/gatsby/image'
// import ImageS4n from './../components/s4n/image'

// const PAGE_2_IMAGE = [
//   `custom/page/about-us.jpg`,     // 24
//   `custom/page/methodology.jpg`,  // 15
//   `custom/page/price-list.jpg`,   // 23
// ];


const IndexPage = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { data } = props;

    // console.log(data)

    // const node = data.allNodePage.edges[0].node;
    // const { node } = data.allNodePage.edges.filter(({ node }) => intl.locale === node.langcode)[0];
    let nodeInLanguage = data.allNodePage.edges.filter(({ node }) => intl.locale === node.langcode); // just for netlify!!!
    nodeInLanguage = !!nodeInLanguage.length ? nodeInLanguage[0].node : null;
    
    let nodeBodyField = null;
    // let nodeParagraphField = null;
    const oReplacement = useReplacementNode(nodeInLanguage);

    // if (nodeInLanguage) {
      // prepare/extract data
      // let nodeBodyField = node.body ? node.body.value : null;
      nodeBodyField = useParseInlineImages(nodeInLanguage && nodeInLanguage.body ? nodeInLanguage.body.value : '', true, oReplacement); // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
      // nodeParagraphField = nodeInLanguage.relationships ? nodeInLanguage.relationships.field_paragraph : null
      // let nodeParagraphField = useParseBodyInlineImages(nodeInLanguage.relationships.field_paragraph)
    // }

    return (
      <Layout node={nodeInLanguage}>

        <SEO 
          nodeId={nodeInLanguage.drupal_internal__nid} 
          title={nodeInLanguage.title} 
          // title={t({ id: `siteMetadata_title_home_page` })} 
          field_metatag={nodeInLanguage.field_metatag}
        />

        {nodeBodyField}    
      </Layout>
    )
}

export default IndexPage

export const query = graphql`
  query {
    allNodePage (
        filter: {
          drupal_internal__nid: {
            eq: 1
          }
          # langcode: {
          #   eq: "pl"
          # }
        }
      ) {
        edges {
          node {
            ...FragmentPageDetails
          }
        }
    }
  }
`